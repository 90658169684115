import client from './api';

class DateService {

	// Tage zwischen heute und einem Datum in der Vergangenheit ermitteln
	daysUntilToday(fromDate) {
		const toDay = new Date();
		const diff = Math.abs(toDay.getTime() - fromDate.getTime());
		const diffInDays = diff / (1000 * 60 * 60 * 24);
		return diffInDays;
	}

	// Lese Daten von einem öffentlichen API-Endpoint mittels Axios
	async getData(from, to, country) {
		const res = await client.get('/', {
			params: {
				country:  country ? country : 'de',
				from:     from.toISOString().substring(0, 10),
				to:       to.toISOString().substring(0, 10),
				size:     Math.floor(this.daysUntilToday(from))
			}
		});
		return (res.data.data);
	};

	// Lese Daten von einem öffentlichen API-Endpoint mittels Axios
	async getCountryData(from, to, country) {
		const res = await client.get('/', {
			params: {
				country:	country ? country : 'de',
				from:     	from.toISOString().substring(0, 10),
				to:			to.toISOString().substring(0, 10),
				size:     	1
			}
		});
		return (res.data.data[0]);
	};

	// Lese Daten von einem öffentlichen API-Endpoint mittels Axios
	async getCountriesData(from, to, countries) {
		await countries.map( async country => { return ( await this.getCountryData(from,to,country) ); });
	};

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DateService();