import { 
    LineChart, 
    Line,
    XAxis, 
    YAxis, 
    CartesianGrid,
    Tooltip, 
    Legend, 
    ResponsiveContainer
 } from 'recharts';

const LineChartView = (props) => {
    if(props.gasstorages.length===0) {
        return (
            <>
            <center>Wählen Sie einen entsprechenden Bereich!</center>
            </>
        );
    }

    // Datenarray erzeugen
    var data = [];
    props.gasstorages.map( 
        // eslint-disable-next-line array-callback-return
        (entry) => {
            let obj = { 
                Datum: entry.gasDayStart, 
                in:     Math.round(parseFloat(entry.injection)), 
                out:    Math.round(parseFloat(entry.withdrawal)), 
                div:    Math.round((parseFloat(entry.injection)-parseFloat(entry.withdrawal))),
                full:   (parseFloat(entry.full))
            };
            data.push(obj);
        }
    );

    // Daten nach Datum aufsteigend umsortieren
    var sortedData = data.sort((a,b) => {
            if( a.Datum > b.Datum )   return  1;
            if( a.Datum < b.Datum  )  return -1;
            return 0;
    });
    
    return (
        <>
        <ResponsiveContainer width={"100%"} height={600}>
            <LineChart  
                data={sortedData}
                margin={{ top: 15, right: 40, left: 40, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Datum" />
                <YAxis yAxisId="left" orientation="left"  label={{ value: 'Einspeisung u. Entnahme in GWh', angle: -90, position: 'insideLeft' }}  />
                <YAxis yAxisId="right" orientation="right" label={{ value: 'Füllstand in %', angle: -90, position: 'insideRight' }}  />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line yAxisId="left" type="monotone" dataKey="out" stroke="#8884d8" activeDot={{ r: 6 }} dot={false}/>
                <Line yAxisId="left" type="monotone" dataKey="in"  stroke="#82ca9d" activeDot={{ r: 6 }} dot={false}/>
                <Line yAxisId="left" type="monotone" dataKey="div" stroke="#ff0000" dot={false} hide={true} />
                <Line yAxisId="right" type="monotone" dataKey="full"  stroke="orange" strokeWidth={2} activeDot={{ r: 6 }} dot={false}/>
            </LineChart>
        </ResponsiveContainer>       
        </>
    );
}

export default LineChartView;