import { useState, useEffect } from 'react';
import dataService from '../../services/data.service';
import Tableview from '../tableView';
import MiniForm from '../miniForm';
import "../../styles/blink.css"


const Table = () => {
    const [loading, setLoading]         = useState(false);
    const [gasstorages, setGasstorages] = useState([]);
    const [from, setFrom]               = useState(null);
    const [to, setTo]                   = useState(null);
    const [country, setCountry]         = useState(null);


    // Lese Daten von einem öffentlichen API-Endpoint mittels Axios
    const getData = async (from, to, country) => {
        try {
            setLoading(true);
            let data = await dataService.getData(from, to, country);
            setLoading(false);
            setGasstorages(data);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // // Lese Daten von einem öffentlichen API-Endpoint mittels Axios
    // const test = async (countries) => {
    //     try {
    //         const from  = new Date(new Date() - 2*(1000*60*60*24));
    //         const to    = new Date(new Date() - 1*(1000*60*60*24));
    //         return await dataService.getCountriesData(from, to, countries);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => { console.log(test(['fr', 'de', 'at'])) }, [] );
    



    if(loading) return (<h3 class='blinking' style={{textAlign: 'center'}}>Loading Data, plaese have a little patience ... </h3>);

    return (
        <>
        <h1 align="center">Zeitlicher Verlauf als Tabelle</h1>
        <MiniForm from={from} to={to} country={country} getData={getData} setFrom={setFrom} setTo={setTo} setCountry={setCountry} />
        <Tableview gasstorages={gasstorages} />
        </>
    );
}

export default Table;