const Tableview = (props) => {
    if(props.gasstorages.length===0) return (
        <>
        <center>Wählen Sie einen entsprechenden Bereich!</center>
        </>
      );
    return (
        <>
        <table align="center" style={{marginTop: "1rem"}}>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Menge in TWh</th> 
            <th>Einspeisung in GWh/d</th>
            <th>Entnahme in GWh/d</th>
            <th>Status</th> 
            <th>Trend</th>
            <th>Füllstand</th>
          </tr>
        </thead>
        <tbody>
          {
            props.gasstorages.map(
              (gasstorage) => {
                return (
                  <tr key={gasstorage.gasDayStart}>
                    <td>{gasstorage.gasDayStart}</td>
                    <td>{gasstorage.gasInStorage}</td>
                    <td>{gasstorage.injection}</td>
                    <td>{gasstorage.withdrawal}</td>
                    <td>{gasstorage.status}</td>
                    <td> { gasstorage.trend<0 ?  <span style={{color: 'red'}}>{gasstorage.trend}</span>  : <span style={{color: 'green'}}>{gasstorage.trend}</span> } </td>
                    <td>{gasstorage.full}</td>
                  </tr>
                )
              }
            )
          }
        </tbody>
      </table>
      </>
    );
}

export default Tableview;