const Links = () => {

    return (
        <>
        <h1>Links zu diesem Projekt</h1>
            <ul>
                <h2>React-Links</h2>
                <ol>
                    <li>React - <a href="https://de.reactjs.org/" target="Links">Eine JavaScript Bibliothek zum Erstellen von Benutzeroberflächen</a></li>
                    <li>React - <a href="https://beta.reactjs.org/" target="Links">Die neue auf Hooks und functional-Components basierte Anleitung in Englisch</a></li>
                    <li>React - <a href="https://lernen.react-js.dev/" target="Links">Lernen und verstehen</a></li>
                </ol>
                <h2>JS Bücher</h2>
                <ol>
                    <li>JSBooks: <a href="https://jsbooks.revolunet.com" target="Links">The best free JavaScript resources</a></li>
                    <li>Eloquent JavaScript: <a href="https://https://eloquentjavascript.net.govdata.de" target="Links">This is a book about JavaScript, programming, and the wonders of the digital.</a></li>
                    <li>Strukturen ... : <a href="https://sourceacademy.org/sicpjs/index" target="Links">Structure and Interpretation of Computer Programs — JavaScript Edition</a></li>
                    <li>Grundlagen: Javascript für Webseiten: <a href="https://www.mediaevent.de/javascript/" target="Links">CSS, HTML und Javascript mit stil</a></li>
                </ol>
                <h2>Verwendete Komponenten in der Reihenfolge der Installation</h2>
                <ol>
                    <li>Axios: <a href="https://axios-http.com/de/docs/intro" target="Links">Der Promise-basierte HTTP-Client für den Browser und Node.js</a></li>
                    <li>React-Router: <a href="https://reactrouter.com/en/main" target="Links">Routing-Funktionalität für Single Page Applikationen (SPA) </a></li>
                    <li>React-Bootstrap: <a href="https://react-bootstrap.github.io/" target="Links">The most popular front-end framework</a></li>
                    <li>Recharts: <a href="https://recharts.org/en-US/guide" target="Links">A composable charting library built on React components.</a></li>
                    <li>Formik: <a href="https://formik.org/" target="Links">Build forms in React, without the tears.</a></li>
                    <li>Yup: <a href="https://www.npmjs.com/package/yup" target="Links">Yup is a JavaScript schema builder for value parsing and validation.</a></li>
                </ol>
                <h2>Öffentlichen APIs</h2>
                <ol>
                    <li>Bund.dev: <a href="https://bund.dev" target="Links">Wir dokumentieren Deutschland</a></li>
                    <li>Open Government: <a href="https://www.govdata.de" target="Links">Verwaltungsdaten transparent, offen und frei nutzbar</a></li>
                    <li>Gas-API-Beschreibung: <a href="https://www.gie.eu/transparency-platform/GIE_API_documentation_v006.pdf" target="Links">API access to AGSI+ / ALSI</a></li>
                    <li>CT-Artikel - Gasspeicher auslesen: <a href="https://lms.berlin-kolleg.de/pluginfile.php/97919/mod_resource/content/0/Datenpipeline.pdf" target="Links">Gasspeicher-Füllstandauswerten</a></li>
                </ol>
            </ul>
        </>
    );

}

export default Links;