import { 
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis, 
    YAxis, 
    CartesianGrid,
    Tooltip, 
    Legend, 
    ResponsiveContainer
 } from 'recharts';

const BarChartView = (props) => {
    if(props.gasstorages.length===0) {
        return (
            <>
            <center>Wählen Sie einen entsprechenden Bereich!</center>
            </>
        );
    }

    // Datenarray erzeugen
    var data = [];
    props.gasstorages.map( 
        // eslint-disable-next-line array-callback-return
        (entry) => {
            let obj = {Datum: entry.gasDayStart, in: parseFloat(entry.injection), out: parseFloat(entry.withdrawal), div: (parseFloat(entry.injection)-parseFloat(entry.withdrawal))};
            data.push(obj);
        }
    );

    // Daten nach Datum aufsteigend umsortieren
    var sortedData = data.sort((a,b) => {
            if( a.Datum > b.Datum )   return  1;
            if( a.Datum < b.Datum  )  return -1;
            return 0;
    });
    
    return (
        <>
        <ResponsiveContainer width={"100%"} height={600}>
            <ComposedChart 
                width={500}
                height={300}
                data={sortedData}
                margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke="#d0d0d0" />
                <XAxis dataKey="Datum" />
                <YAxis label={{ value: 'Einspeisung u. Entnahme in GWh', angle: -90, position: 'insideLeft' }}/>
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="in" fill="#ffffd8" stroke="#00aa00" />
                <Bar dataKey="out" barSize={5} fill="#8884d8" />
                <Line type="monotone" dataKey="out" stroke="#ff7300" dot={false} />
            </ComposedChart>
        </ResponsiveContainer>    
        </>
    );
}

export default BarChartView;