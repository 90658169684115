import logo from "../../img/datenquell.jpg";

const Home = () => {
    return (
        <>
        <h1>Sprudelnder Datenquell</h1>
        <h2>Schöner programmieren mit öffentlichen APIs</h2>
        <div style={{paddingBottom: '2em'}}>
            Programmieren macht Spaß – am meisten, wenn man mit echten Daten arbeitet und daraus etwas Neues erschafft. Nicht immer muss man diese Daten selbst sammeln, es gibt zahlreiche Programmierschnitt­ stellen, die wertvolle Daten­ schätze anzapfen. Wir zeigen, wie die öffentlichen Schnitt­ stellen funktionieren und stellen die Lieblings­APIs der Redaktion vor.
        </div>
        <img src={logo} style={{maxWidth: '100%', height: 'auto'}} alt="Logo im Kopf der Seite" />
        <p style={{fontSize: 'smal'}}>© Copyright by Heise Medien. Nutzung im Informatikunterricht</p>
        </>
    );
}

export default Home;