import axios from 'axios';

const client = axios.create({
	baseURL: 'https://agsi.gie.eu/api',
	headers: {
		'Content-Type': 'application/json',
        'x-key': process.env.REACT_APP_API_KEY,
	}
});

export default client;