import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { eu } from '../data/euCodes';

const MiniForm = (props) => {
    const max       = new Date(new Date() - (1000*60*60*24));
    const min       = new Date("2022-03-01");
    let from        = (!props.from) ? min : props.from;
    let to          = (!props.to) ? max : props.to;
    let state       = (!props.country) ? "DE" : props.country;

    // console.log(eu)

    return (
        <div style={{marginBottom: '1em'}}>
        <Formik
            initialValues={{ 
                begin: from.toISOString().substring(0, 10), 
                end:  to.toISOString().substring(0, 10), 
                country: state
            }}
            validationSchema={Yup.object({
                begin: Yup.date()
                    .typeError("Das Begin-Datum hat ein falsche Format.")
                    .required()
                    .min(min.toISOString().substring(0, 10), `Als kleinstes Datum für den Beginn ist der nur ${min.toISOString().substring(0, 10)}  möglich.`)
                    .max(max.toISOString().substring(0, 10), `Als größtes Datum für den Beginn ist maximal ${max.toISOString().substring(0, 10)} möglich.`),

                end: Yup.date()
                    .typeError("Das Ende-Datum hat ein falsche Format.")
                    .required()
                    .min(min.toISOString().substring(0, 10), `Als kleinstes Datum für das Ende ist nur ${min.toISOString().substring(0, 10)} möglich.`)
                    .max(max.toISOString().substring(0, 10), `Als größtes Datum für das Ende ist maximal ${max.toISOString().substring(0, 10)} möglich.`),

                country: Yup.string().required(),
            })}
            onSubmit={(values, { setSubmitting }) => {
                from  = new Date(values.begin);
                to    = new Date(values.end);
                state = values.country;
                props.setTo(to);
                props.setFrom(from);
                props.setCountry(state);
                props.getData(from, to, values.country);
                setSubmitting(false);
            }}
        >
            <Form style={{textAlign: 'center'}}>
                <label htmlFor="Countries"></label>
                <Field as="select" name="country"> 
                    { eu.map( (country) => { return (<option key={country.code} value={country.code}>{country.name}</option>);}) }
                </Field>  

                <label htmlFor="begin"> von </label>
                <Field name="begin" type="date"/>
                
                <label htmlFor="end"> bis </label>
                <Field name="end" type="date" />
                
                <label htmlFor="Submit"> - </label>
                <button type="submit" > Daten abrufen </button>

                <div style={{marginTop: '0.5em', color: 'red', fontSize: 'x-smal'}}>
                    <ErrorMessage name="begin" />
                    <ErrorMessage name="end" />
                    <ErrorMessage name="country" />
                </div>
            </Form>

        </Formik>
        </div>
    );
}

export default MiniForm;