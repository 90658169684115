import './App.css';
import Navbar from './components/navBar';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/home';
import Links from './components/pages/links';
import LineChart from './components/pages/lineChart';
import ComposedChart from './components/pages/composedChart';
import Table from './components/pages/table';

const App = () => {
  return (
      <>
      <Navbar />
      <div className='container'>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/table" element={<Table />} />
          <Route exact path="/lineChart" element={<LineChart />} />
          <Route exact path="/composedChart" element={<ComposedChart />} />
          <Route exact path="/links" element={<Links />} />
        </Routes>
      </div>
      </>
  );
}

export default App;