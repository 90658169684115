import { useState } from 'react';
import dataService from '../../services/data.service';
import LineChartView from '../lineChartView';
import MiniForm from '../miniForm';
import "../../styles/blink.css"

const LineChart = () => {
    const [loading, setLoading]         = useState(false);
    const [gasstorages, setGasstorages] = useState([]);
    const [from, setFrom]               = useState(null);
    const [to, setTo]                   = useState(null);
    const [country, setCountry]         = useState(null);

    // Lese Daten von einem öffentlichen API-Endpoint mittels Axios
    const getData = async (from, to, country) => {
        try {
            setLoading(true);
            let data = await dataService.getData(from, to, country);
            setLoading(false);
            setGasstorages(data);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    if(loading) return (
        <h3 class='blinking' style={{textAlign: 'center'}}>Loading Data, plaese have a little patience ... </h3>
    );

    return (
        <>
        <h1 align="center">Zeitlicher Verlauf als Linien-Diagramm</h1>
        <MiniForm from={from} to={to} country={country} getData={getData} setFrom={setFrom} setTo={setTo} setCountry={setCountry}/>
        <LineChartView gasstorages={gasstorages} />
        </>
    );
}

export default LineChart;